import React ,{Component} from "react";
import Form from "./components/form";


class App extends Component {
  render(){
    return (
      <div>
          <Form />
      </div>
    );
  } 
}

export default App;
