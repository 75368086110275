import React, { Component } from 'react';

class Form extends Component {
    state = {
        username:"", usernameVaild:false,
        formVaild: false,
        errorMsg: {},
    }
    validateForm = () =>{
        const {usernameVaild} = this.state;
        this.setState({formVaild:usernameVaild})
    }
    vaildateUsername = () => {
        const {username} = this.state;
        let usernameVaild = true;
        let errorMsg = {...this.state.errorMsg}
        if(username.length < 6){
            usernameVaild = false;
            errorMsg.username = "username soul be then 15 charasd";
        }
        this.setState({usernameVaild , errorMsg} , this.validateForm);
    }
    
    render() {
        return (
            <div>
                <h1>{this.state.username || "dont found any value"}</h1>
                <form>
                    <input type="text" id="username" value={this.state.username} onChange={(e)=> {this.setState({username:e.target.value} , this.vaildateUsername)}} />
                </form>
            </div>
        );
    }
}

export default Form;
